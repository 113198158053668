import React from "react"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"

//gatsby
import { Link, useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

//component
import Layout from "../../components/Layout"
import ThirdLayerHeader from "../../components/ThirdLayerHeader"
import ThirdLayerClose from "../../components/ThirdLayerClose"
import { ThirdLayer, Main } from "../../components/ThirdLayerBody"
import MaleFemale from "../../components/imageComps/MaleFemale"
// import GraphImage from "../../components/imageComps/GraphImage"
import GraphFiber from "../../components/imageComps/GraphFiber"
import SwipeMotionDiv from "components/ui/SwipeMotionDiv"
import SEO from "../../components/SEO"

const TendToLack = props => {
  const data = useStaticQuery(query)
  const { location, transitionStatus, entry, exit } = props
  const parentPath = "/" + location.pathname.split("/")[1]

  const jsonld = [
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": `${data.seo.top.pass}`,
            name: `${data.seo.top.title}`,
          },
        },
        {
          "@type": "ListItem",
          position: 2,
          item: {
            "@id": `${data.seo.page.bitsKnow.pass}`,
            name: `${data.seo.page.bitsKnow.title}`,
          },
        },
        {
          "@type": "ListItem",
          position: 3,
          item: {
            "@id": `${data.seo.page.bitsKnow.tendLack.pass}`,
            name: `${data.seo.page.bitsKnow.tendLack.title}`,
          },
        },
      ],
    },
  ]

  return (
    <ThirdLayer>
      <Layout>
        <SEO
          title={data.seo.page.bitsKnow.tendLack.title}
          pageUrl={data.seo.page.bitsKnow.tendLack.pass}
          description={data.seo.page.bitsKnow.tendLack.description}
          imgUrl="/mainVisual.jpg"
          type="article"
          jsonld={jsonld}
        />
        <SwipeMotionDiv
          entry={entry}
          exit={exit}
          transitionStatus={transitionStatus}
        >
          <ThirdLayerHeader
            text={data.text.tendToLack.leadText}
            parentPath={parentPath}
          />
          <Main>
            <p>{data.text.tendToLack.paragraph1}</p>
            <MaleFemale />
            <GraphFiber />
          </Main>
          <ThirdLayerClose parentPath={parentPath} />
        </SwipeMotionDiv>
      </Layout>
    </ThirdLayer>
  )
}

export const query = graphql`
  {
    text: pagesYaml(pageName: { eq: "bitsOfKnowledgePage" }) {
      attention {
        leadText
        paragraph1
        paragraph2
        ref
        title
      }
      tendToLack {
        leadText
        paragraph1
        title
      }
      supporter {
        leadText
        title
        insolubleBlock {
          blockTitle
          comment
          innerBoxText
          paragraph1
          paragraph2
        }
        waterSolubleBlock {
          blockTitle
          comment
          innerBoxText
          paragraph1
        }
      }
    }
    seo: dataYaml {
      top {
        description
        pass
        title
      }
      page {
        bitsKnow {
          description
          pass
          title
          tendLack {
            title
            pass
            description
          }
        }
      }
    }
  }
`

export default TendToLack
