import React from "react"
import styled, { css } from "styled-components"

const StyledBubble = styled.p`
  display: flex;
  font-size: 1.25rem;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-block: 16px !important;
  padding-inline: 24px !important;
  background-color: #fff;
  position: absolute;
  top: 20%;
  left: 26%;
  border-radius: 8px;
  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: 16px solid transparent;
    border-top-color: #fff;
  }
  span:nth-child(2) {
    color: red;
    font-weight: bold;
  }

  @media (max-width: 600px) {
    font-size: 1rem;
    padding-block: 12px !important;
    padding-inline: 18px !important;
    top: 22%;
  }
  @media (max-width: 500px) {
    left: 24%;
  }
  @media (max-width: 480px) {
    top: 24%;
    left: 30%;
    padding-inline: 8px !important;
    font-size: 0.75rem;
  }
  @media (max-width: 380px) {
    opacity: 0.8;
  }
`

export default function BubbleText() {
  // const data = useStaticQuery(query)

  return (
    <StyledBubble>
      <span>20~50才の</span>
      <span>「働き盛りに不足しがち」</span>
      <span>な食物繊維</span>
    </StyledBubble>
  )
}
