import React from "react"
import styled, { css } from "styled-components"
import Graph from "./Graph"
import BubbleText from "./BubbleText"
import { FlexCenter } from "../../CssTools"
import { media } from "../../../styles/media"

const StyledWrap = styled.div`
  ${() =>
    media.tablet(css`
      // max-width: 500px;
      margin: 16px auto 32px;
    `)}
  width: calc(100% - 32px);
  margin-inline: auto;
  height: 100%;
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.primary.brown};
  // padding-bottom: 32px;
  // margin-bottom: 32px;
  // p {
  //   font-size: 12px;
  //   line-height: 1.1em;
  // }
  h3 {
    width: 100%;
    font-size: 12px;
    margin: 30px 0 16px;
    ${FlexCenter}
    line-height:14px;
    flex-flow: row nowrap;
    span {
      font-family: ${({ theme }) => theme.colors.font.eng};
      margin: 0 8px;
      font-size: 14px;
      font-weight: 800;
    }
  }
`

export default function GraphFiber() {
  return (
    <StyledWrap>
      <BubbleText />
      <Graph />
    </StyledWrap>
  )
}

// export const query = graphql`
//   {
//     text: pagesYaml(pageName: { eq: "bitsOfKnowledgePage" }) {
//       tendToLack {
//         imgtxt
//       }
//     }
//   }
// `
