import React from "react"
import { Bar } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
)

const textPlugin = {
  id: "textPlugin",
  afterDraw: chart => {
    const {
      ctx,
      chartArea: { bottom, left, right },
    } = chart
    ctx.save()
    ctx.font = "14px"
    ctx.fillStyle = "black"
    ctx.textAlign = "left"
    if (chart.width < 600 - 32) {
      ctx.fillText(
        "※厚生労働省「日本人の食事摂取基準」（2020年版)",
        left - 36,
        bottom + 64
      )
      ctx.fillText("「国民健康・栄養調査」（令和4年）", left - 36, bottom + 80)
    } else {
      ctx.fillText(
        "※厚生労働省「日本人の食事摂取基準」（2020年版)",
        left - 36,
        bottom + 56
      )
      ctx.fillText("「国民健康・栄養調査」（令和4年）", left - 36, bottom + 72)
    }
    ctx.restore()
  },
}

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 20,
      right: 20,
      bottom: 80,
      top: 20,
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        align: "center",
        text: "単位: g", // Y軸に表示されるラベル
      },
      ticks: {
        callback: value => value.toFixed(1), // Y軸のラベルに単位を付ける
        stepSize: 2,
      },

      border: {
        display: false,
      },
    },
    x: {
      grid: {
        display: false,
      },
    },
    // border: {
    //   display: false,
    // },
  },
  plugins: {
    legend: {
      position: "top",
      title: {
        text: "性別",
      },
    },
    title: {
      display: true,
      text: "【食物繊維の目標摂取量と実際の差】",
      font: {
        size: ctx => {
          if (ctx.chart.width < 400 - 32) return 16
          return 20
        },
        family: "'Noto Sans JP', sans-serif",
        // weight: "bold",
      },
      color: "#292929",
    },

    tooltip: {
      enabled: true,
    },
    datalabels: {
      color: context => {
        // データの値に応じて色を変更
        const value = context.dataset.data[context.dataIndex]
        return value < 0 ? "red" : "black"
      },
      // anchor: "end", // ラベルの位置（バーのどこに表示するか）
      anchor: context => {
        // データの値に応じて位置を変更
        const value = context.dataset.data[context.dataIndex]
        return value < 0 ? "start" : "end"
      },
      // align: "top", // バーの上に表示
      align: context => {
        // データの値に応じて位置を変更
        const value = context.dataset.data[context.dataIndex]
        return value < 0 ? "bottom" : "top"
      },

      font: context => {
        if (context.chart.width < 400 - 32) return { size: 10 }
        if (context.chart.width < 700 - 32) return { size: 12 }
        return {
          size: 14,
        }
      },
      formatter: value => value.toFixed(1), // デフォルトで数値をそのまま表示
    },
  },
}

const labels = [
  "7-14才",
  "15-19才",
  "20-29才",
  "30-39才",
  "40-49才",
  "50-59才",
  "60-69才",
  "70-79才",
  "80才以上",
]

export const d = {
  labels,
  datasets: [
    {
      label: "男性",
      data: [0.5, -0.2, -3.4, -2.8, -3.4, -2.1, -0.9, 1.0, 1.5],
      backgroundColor: "rgb(114, 179, 197)",
    },
    {
      label: "女性",
      data: [-0.8, -2.7, -4.3, -2.5, -2.7, -1.1, 0.6, 3.1, 2.0],
      backgroundColor: "rgb(240, 164, 148)",
      datalabels: {
        // clamp: true,
        // align: "bottom",
        // anchor: "start",
      },
    },
  ],
}

export default function Graph() {
  return <Bar options={options} data={d} plugins={[textPlugin]} height={600} />
}
